<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="topsubmenu auto">
          <a-radio-group default-value="4" size="small" @change="changeSubmenu" button-style="solid">
            <a-radio-button value="1">
              账号安全策略
            </a-radio-button>
            <a-radio-button value="4">
              Syslog配置
            </a-radio-button>
            <a-radio-button value="5">
              属性配置
            </a-radio-button>
            <a-radio-button value="2">
              基本配置
            </a-radio-button>
            <a-radio-button value="3">
              系统准入策略
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>Syslog配置</b></span>
          </div>
        </div>
        <div class="department-content auto" id="no-margin-form">
          <div class="box auto">
            <div class="box-title auto">
              <!-- <div class="fl">密码校验管理</div> -->
            </div>
            <div class="box-con auto m-t-20 no-bor">
              <a-form-model
                :label-col="{span: 4}"
                :wrapper-col="{span: 18}"
                labelAlign="right"
                style="max-width: 760px;"
              >
                <a-form-model-item label="Syslog服务器地址" prop="ip">
                  <a-input placeholder="请输入服务器 [IP：端口号]" v-model="ip" size="small" style="width:312px;margin-right: 5px;"></a-input>
                  <a-button type="primary" size="small" @click="addIP">添加</a-button>
                </a-form-model-item>
                <a-form-model-item label="已添加服务器" prop="form">
                  <div class="ipbox">
                    <ul>
                      <li v-for="(item, index) in form" :key="index"><span>{{ item }}</span><a-icon type="delete" class="delete-icon" @click="deleteIP(index)" /></li>
                    </ul>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="saveSyslogsetting">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      ip: '',
      form: []
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/configuration/index');
          break;
        case 2:
          this.$router.push('/system/configuration/basic');
          break;
        case 3:
          this.$router.push('/system/configuration/accesspolicy');
          break;
        case 4:
          this.$router.push('/system/configuration/syslogsetting');
          break;
        case 5:
          this.$router.push('/system/configuration/property');
          break;
        default:
          break;
      }
    },
    addIP () {
      if (!this.ip) {
        this.$message.error('请填写地址')
        return false
      }
      var hasRepeat = this.form.find(item => {
        return item === this.ip
      })
      if (hasRepeat) {
        this.$message.error('已有重复IP！')
      } else {
        this.form.push(this.ip)
        this.ip = ''
      }
    },
    deleteIP (index) {
      this.form.splice(index, 1)
    },
    async getSyslogsetting () {
      this.loading = true
      let res = await System.getSyslogsetting()
      this.loading = false
      if (!res['code']) {
        this.form = res['data']
        console.log(this.form)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async saveSyslogsetting () {
      this.loading = true
      let res = await System.putSyslogsetting(this.form)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getSyslogsetting()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 0px;
  color: rgba(0,0,0,0.85);
}

.box {
}
.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  width: 100%;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  width: 100%;
  padding-bottom: 20px;
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}
.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}

.ipbox {
  width: 312px;
  height: 113px;
  overflow: hidden;
  overflow: auto;
  border: 1px solid #d9d9d9;
}
.ipbox ul li {
  width: 100%;
  height: 22px;
  line-height: 22px;
  margin: 2px 0;
}
.ipbox ul li:hover {
  background: #E6F7FF;
}
.ipbox ul li span {
  float: left;
  margin-left: 10px;
}
.ipbox ul li i {
  float: right;
  margin-right: 10px;
  margin-top: 4px;
  color:#226FBB;
}
</style>
